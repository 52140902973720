<template>
  <v-container class="pa-lg-6 pa-md-0" fluid>
      <CardStatusBlock :page="1" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CardStatusBlock from '@/components/CardStatusBlock';

export default {
  name: 'NewOrders',
  components: {
    CardStatusBlock,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getUserData: 'app/getUserData',
    }),
    isDealer(){
      return this.getUserData.roleNames[0].toLowerCase() === 'dealer'
    },
    isAdmin(){
      return this.getUserData.roleNames[0].toLowerCase() === 'admin'
    }
  },
  mounted(){
    if(this.isDealer || this.isAdmin) {
      this.$router.push({path:'/venues'})
    }
  }
};
</script>

<style lang="scss" scoped></style>
